import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

function Login() {
  const [email, setEmail] = useState('');
  const [paycorid, setPaycorid] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await api.login({ email, paycorid });
      if (response.success) {
        localStorage.setItem('userId', response.userId);
        localStorage.setItem('resourceId', response.resourceId);
        navigate('/timesheet');
      } else {
        setError('Invalid Email or Paycor ID combination');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.response?.status === 401 || err.response?.status === 403) {
        setError('Invalid Email or Paycor ID combination');
      } else {
        setError('Unable to connect to the server. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <img src="/SISlogo.JPG" alt="SIS Logo" className="login-logo" />
        <h2>Timesheet</h2>
      </div>

      <form onSubmit={handleSubmit} className="login-form">
        {error && <div className="error">{error}</div>}
        
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <div className="form-group">
          <label htmlFor="paycorid">Paycor ID:</label>
          <input
            type="text"
            id="paycorid"
            value={paycorid}
            onChange={(e) => setPaycorid(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
}

export default Login; 