import axios from 'axios';

// Remove trailing slash if present
const API_BASE_URL = process.env.REACT_APP_API_URL;
console.log('API Base URL Configuration:', {
  envUrl: process.env.REACT_APP_API_URL,
  finalUrl: API_BASE_URL
});

// Create an axios instance with default config
const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add request interceptor for debugging
api.interceptors.request.use(
  config => {
    console.log('API Request:', {
      url: config.url,
      method: config.method,
      data: config.data,
      fullUrl: `${config.baseURL}${config.url}`
    });
    return config;
  },
  error => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for debugging
api.interceptors.response.use(
  response => response.data,
  error => {
    console.error('Response error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    return Promise.reject(error);
  }
);

// API Functions
const apiFunctions = {
  login: async ({ email, paycorid }) => {
    console.log('Sending login request:', { email, paycorid });
    
    const response = await axios.post(`${API_BASE_URL}/login`, {
      email,
      paycorid
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  },

  getDynamicsTimesheet: async ({ resourceId, weekDates }) => {
    if (!resourceId) {
      throw new Error('ResourceId is required');
    }

    console.log('Fetching timesheet:', { resourceId, weekDates });

    const response = await axios.post(`${API_BASE_URL}/timesheet`, {
      resourceId,
      weekDates
    });

    return response.data;
  }
};

export default apiFunctions; 