import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import TimeSheet from './pages/TimeSheet';
import './App.css';

// Simple protected route component
function ProtectedRoute({ children }) {
  const isAuthenticated = localStorage.getItem('userId');
  const resourceId = localStorage.getItem('resourceId');
  
  console.log('ProtectedRoute Check:', {
    path: window.location.pathname,
    isAuthenticated,
    resourceId,
    hasChildren: !!children
  });
  
  if (!isAuthenticated) {
    console.log('ProtectedRoute: Not authenticated, redirecting to login');
    return <Navigate to="/login" replace />;
  }

  console.log('ProtectedRoute: Authenticated, rendering children');
  return children;
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/timesheet" 
          element={
            <ProtectedRoute>
              <TimeSheet />
            </ProtectedRoute>
          } 
        />
        <Route path="/" element={<Navigate to="/timesheet" />} />
      </Routes>
    </div>
  );
}

export default App;
