function getPayrollWeekInfo(inputDate = new Date()) {
  // Calculate week ending (Sunday)
  const weekEnding = new Date(inputDate);
  weekEnding.setDate(weekEnding.getDate() + (7 - weekEnding.getDay()));

  // Calculate if it's week 1 or 2 in the pay period
  const startOf2025 = new Date(2025, 0, 1);
  const diffTime = weekEnding.getTime() - startOf2025.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const weekNum = (Math.floor(diffDays / 7) % 2) + 1;

  // Calculate week start (Monday)
  const weekStart = new Date(weekEnding);
  weekStart.setDate(weekEnding.getDate() - 6);

  return {
    weekNum,
    weekStart,
    weekEnding,
    filterDates: {
      start: weekStart.toISOString().split('T')[0],
      end: weekEnding.toISOString().split('T')[0]
    },
    displayText: `${weekEnding.toLocaleDateString('en-US', { 
      month: '2-digit',
      day: '2-digit',
      year: '2-digit'
    })} [Week ${weekNum}]`
  };
}

function getWeekOptions() {
  const options = [];
  const currentDate = new Date();
  
  // Start with current date
  let weekDate = new Date(currentDate);
  
  // Get exactly 5 weeks (current + 4 previous)
  for (let i = 0; i < 5; i++) {
    options.push(getPayrollWeekInfo(weekDate));
    // Move back exactly 7 days
    weekDate = new Date(weekDate.getTime() - (7 * 24 * 60 * 60 * 1000));
  }
  
  // Sort by week ending date (most recent first)
  return options.sort((a, b) => b.weekEnding - a.weekEnding);
}

export { getPayrollWeekInfo, getWeekOptions }; 